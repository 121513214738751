
import React from 'react'
import { Link as GLink } from 'gatsby'
import { Link, Box, useThemeUI, get } from 'theme-ui'
import { buildResponsiveVariant as rv } from '@components/utils'
import CardMediaIcon from '@components/Card/Card.Media.Icon'
import CardMediaImage from '@components/Card/Card.Media.Image'
import "./card.media.css";

const DEFAULT_IMAGE_VARIANT = 'vertical'

const styles = {
  link: {
    userSelect: `none`,
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  }
}

const getMediaImage = (context, props) => {
  const {imageVariant, variant, mediaType, thumbnail, thumbnailText} = props;
  const imageVar =
  imageVariant ||
  get(context.theme, rv(variant, 'imageVariant')[0]) ||
  DEFAULT_IMAGE_VARIANT

  const image = thumbnail && thumbnail[imageVar];

  return mediaType === 'image' && image ? (
    <CardMediaImage image={image} {...props} />
  ) : (mediaType === 'icon' || (!image && thumbnailText)) && (
    <CardMediaIcon {...props} />
  );
}

const getSrcImage = ({thumbnailSrc, thumbnailText}) => {
  return <img className="image-from-src" src={thumbnailSrc} alt={thumbnailText || ""} />
}

const CardMedia = ({
  imageVariant,
  omitMedia,
  mediaType,
  title,
  slug,
  link,
  ...props
}) => {
  const context = useThemeUI();

  if (omitMedia) return null

  const { variant, thumbnailSrc } = props

  const linkProps = link
    ? {
        as: 'a',
        href: link,
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {
        as: GLink,
        to: slug
      }

  return (
    <Box sx={{ variant: rv(variant, 'media') }}>
      <Link {...linkProps} sx={styles.link} aria-label={title}>
        {!!thumbnailSrc ? getSrcImage(props) : getMediaImage(context, props)}
      </Link>
    </Box>
  )
}

CardMedia.defaultProps = {
  mediaType: 'image'
}

export default CardMedia
